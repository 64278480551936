import { template as template_f370808edaaa4abe91d0aed054c1889a } from "@ember/template-compiler";
const SidebarSectionMessage = template_f370808edaaa4abe91d0aed054c1889a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
