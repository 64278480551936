import { template as template_60ce5353f88341bc983db10ffba810d4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_60ce5353f88341bc983db10ffba810d4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
