import { template as template_db3e0b6faf9c432c90d45e33b7ef3fbb } from "@ember/template-compiler";
const WelcomeHeader = template_db3e0b6faf9c432c90d45e33b7ef3fbb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
